import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useHistory } from "react-router-dom";

import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';

import web3ModalConfig from "../providerOption";
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';

import mainlogo from '../assets/rocket.png'


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const theme = createTheme();

const styles = theme => ({
    multilineColor:{
        color:'red'
    }
});

const style = {"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '1px solid green'}}}

export default function SignIn() {
  const dispatch = useDispatch();
  let history = useHistory();

  const application = useSelector(state => ({
    ...state.application
  }));

  const [provider, setProvider] = React.useState();
  const [library, setLibrary] = React.useState();
  const [account, setAccount] = React.useState();
  const [network, setNetwork] = React.useState();
  const [chainId, setChainId] = React.useState();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (application.isAuthenticated === true){
      history.push("/home")
    }
  }, []);

  const handleAccountsChanged = (accounts) => {
    console.log({accounts})
  };

  const connectWallet = async () => {
    const provider = await web3ModalConfig.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();

    if (network?.chainId !== 56){
      alert("Wrong network selected")
      return;
    }
    setProvider(provider);
    setLibrary(library);
    if (accounts) setAccount(accounts[0]);
    setNetwork(network);
    setChainId(network.chainId);

    loginProcess(accounts[0], network.chainId);

  };

  const loginProcess = (address, chainId) => {
    setOpen(true);
    secureAxios
        .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/login`, {address: address}).then(response => {
          setOpen(false);
          if (response.data?.success){
            localStorage.setItem('accessToken', response.data?.token?.access);
            dispatch({
              type: LOGIN,
              payload: {
                profile: {
                  account: address,
                  chainId: chainId,          
                },
                isAuthenticated: true
              }
            });
            history.push("/home");
          }
        }).catch(error => {
          setOpen(false);
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }

        })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    dispatch({
      type: LOGIN,
      payload: {
        profile: {},
        isAuthenticated: true,
        accessToken: {},
        groups: {}
      }
    });
    history.push("/home")

  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
  };

  React.useEffect(() => {
    const bodyElt = document.querySelector("body");
    bodyElt.style.background = "rgb(255,255,255)";
  
    return //This is important
   
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <IBackdrop isOpen={open} msg={"Signin progress"}/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            paddingTop: 5,
            paddingBottom:5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow:  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'transparent' }} style={{margin: '0px auto'}}>
            <img style={{width: 25}} src={mainlogo} alt="fireSpot"/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>           
            <Button
              style={{
                borderRadius: 35,
                color: 'rgb(24, 26, 32)',
                backgroundColor: "rgb(252, 213, 53)",
                fontSize: "14px",
                marginTop: '10px',
                marginBottom: '10px',
                width: '200px'
            }} variant="contained"
            onClick={connectWallet}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="signup" style={{textDecoration: 'none', color: 'rgb(30, 35, 41)', fontSize: '12px'}} variant="body2">
                  {"Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}