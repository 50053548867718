import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import mainlogo from '../assets/icon.svg'
import mineLogo from '../assets/mining_selected.svg'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import usdtlogo from '../assets/usdt.png'
import slplogo from '../assets/slp.png'
import xeclogo from '../assets/xec.png'
import jasmylogo from '../assets/jasmy.png'
import nfts from '../assets/nfts.png'

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Countdown from 'react-countdown';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

import StarRateIcon from '@mui/icons-material/StarRate';

import WithdrawDialog from '../components/withdrawal.js';
import TransferDialog from '../components/transfer.js';
import ConvertDialog from '../components/convert.js';
import ReddemDialog from '../components/reddem.js';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';

import web3ModalConfig from "../providerOption";

import GridTable from '@nadavshaar/react-grid-table';
import moment from 'moment/moment';

import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoIcon from '@mui/icons-material/Info';
 

import btt from "../assets/btt.svg";
import usdt from "../assets/usdt.png";

function getIcon(coin_code) {
    if (coin_code === 'usdt'){
        return usdt
    }
    else if (coin_code === 'btt') {
        return btt
    }
    else{
      return nfts
    }
  }


const user_details = {
  "username": "YOUR_USERNAME",
  "stakes": [],
  "balance": [
    {
      "code": "usdt",
      "balance": 0,
      "limit": 2,
      "value": 1
    },
    {
      "code": "btt",
      "balance": 0,
      "limit": 2,
      "value": 1
    },
    {
      "code": "star_nft",
      "balance": 0,
      "limit": 2,
      "value": 1
    }
  ],
  "totalIncome": 0,
  "totalBalance": 0
}
  
const Wallet = (props) => {
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    const [isOpen, setOpen] = React.useState(false);
    const [userDetails, setUserDetails] = React.useState(user_details);
    const [withdrawModal, setwithdrawModal] = React.useState(false);
    const [transferModal, setTransferwModal] = React.useState(false);
    const [bttConvertModal, setbttConvertModal] = React.useState(false);
    const [reedemModal, setreedemModal] = React.useState(false);
    const [isBTT, setIsBTT] = React.useState(false);
    const [coinName, setCoinName] = React.useState("");
    const [coin_code, setCoinCode] = React.useState("star_nft");

    const Profiledetails = () => {
        setOpen(true);
        secureAxios
            .get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/balance/wallet`).then(response => {
              if (response.data){
                setOpen(false);
                setUserDetails(response.data);
              }
            }).catch(error => {
                setOpen(false);
          })
      }
    
    const handleWithDrawSubmit = (data) => {
        withdraw(data);
    }

    const handleTransferSubmit = (data) => {
        transfer(data);
    }

    const transfer = (data) => {
      console.log(data)
      const { addressOrPin, amount, pin, coin_code } = data;
      if (pin == ''){
        alert('Pin Required');
        return
      }
      else if (addressOrPin == ''){
        alert('BUSD address or user id required');
        return
      }
      setOpen(true);
      setTransferwModal(false);
      secureAxios
        .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/transfer`, {amount: parseFloat(amount), pin: parseInt(pin), address_or_pin: addressOrPin, coin_code: coin_code}).then(response => {
          setOpen(false);
          alert("Transfer Successfully");
          Profiledetails();
          setTransferwModal(false);
        }).catch(error => {
          setOpen(false);
          setTransferwModal(true);

          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }
        })
    }

    const unlockStake = (data) => {
      const {id, PIN} = data
      setOpen(true);
      secureAxios
          .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/unlock`, {stake_id: id, pin: parseInt(PIN)}).then(response => {
            setOpen(false);
            alert("Unlocked Successfully");
            Profiledetails();
          }).catch(error => {
            setOpen(false);
            if (error.response.data?.non_field_errors){
              alert(error.response.data?.non_field_errors)
            }
            else{
              alert(error.response.data?.detail)
            }
          })
    }


    const withdraw = (data) => {
        const { amount, pin, coinCode } = data;
        if (pin == ''){
          alert('Pin Required');
          return
        }
        setOpen(true);
        setwithdrawModal(false);
        secureAxios
          .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/withdraw`, {amount: parseFloat(amount), pin: parseInt(pin), coin_code: coinCode}).then(response => {
            setOpen(false);
            alert("Withdraw Successfully");
            setwithdrawModal(false);
            Profiledetails();
          }).catch(error => {
            setOpen(false);
            setwithdrawModal(true);
            if (error.response.data?.non_field_errors){
              alert(error.response.data?.non_field_errors)
            }
            else{
              alert(error.response.data?.detail)
            }
          })
      }

    const handleConvertSubmit = (data) => {
      convert(data);
    }

    const convert = (data) => {
      const { amount, pin } = data;
      if (pin == ''){
        alert('Pin Required');
        return
      }
      else if (amount < 100){
        alert('Amount should be getter then');
        return
      }
      setOpen(true);
      setbttConvertModal(false);
      
      secureAxios
        .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/convert`, {amount: parseFloat(amount), pin: parseInt(pin)}).then(response => {
          setOpen(false);
          alert("Convert Successfully");
          Profiledetails();
          setbttConvertModal(false);
        }).catch(error => {
          setOpen(false);
          setbttConvertModal(false);

          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }
        })
    }

    const handleReddem = (data) => {
      const { amount, pin, coinCode } = data;
        if (pin == ''){
          alert('Pin Required');
          return
        }
        setOpen(true);
        setreedemModal(false);
        secureAxios
          .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/reddem`, {amount: parseFloat(amount), pin: parseInt(pin), coin: coinCode}).then(response => {
            setOpen(false);
            alert("Reddem Successfully");
            setreedemModal(false);
            Profiledetails();
          }).catch(error => {
            setOpen(false);
            setreedemModal(true);
            if (error.response.data?.non_field_errors){
              alert(error.response.data?.non_field_errors)
            }
            else{
              alert(error.response.data?.detail)
            }
          })
    }
    
    React.useEffect(() => {
        Profiledetails()
    return
    }, []);

    const openReddemModal = (item) => {
      setCoinName(item?.code);
      setCoinCode(item?.coin_code);
      setreedemModal(true);
    }

    return (
    <>

    <IBackdrop isOpen={isOpen} msg={"Signin progress"}/>
    
   {<><Card sx={{ minWidth: 275 }} variant="outlined" style={{background: "rgb(26,26,26)"}}>
        <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        
        <Typography variant="body1" sx={{ fontSize: 26, marginTop: '5px' }} color="text.secondary" gutterBottom>
            Total Balance
        </Typography>

        <Typography variant="body1" sx={{ fontSize: 60, marginTop: '5px', fontWeight: 'bold'}} color="text.secondary" gutterBottom>
           ${userDetails?.totalBalance?.toFixed(2)}
        </Typography>

        <Typography variant="body1" sx={{ fontSize: 10, marginTop: '5px', fontWeight: 'bold'}} color="text.secondary" gutterBottom>
          {userDetails?.address}
        </Typography>

        </CardContent>
    </Card>

    <Grid container xs={12} sx={{marginTop: '10px'}} spacing={2}  justifyContent="center" alignContent={'center'}>
      
        {/* <Grid item xs={12}>
            <Button onClick={() => setbttConvertModal(true)} sx={{ marginTop: '10px', background: '#05b4cc', color: '#fff !important', width: '100%' }} variant="contained">Convert BTT - USDT</Button>
        </Grid> */}
        {/* <Grid item xs={6}>
            <Button sx={{ marginTop: '5px', background: '#05b4cc', color: '#fff !important' , width: '100%' }} variant="contained">Swap</Button>
        </Grid> */}
        <Grid item xs={12}>
            <Button onClick={() => setTransferwModal(true)} sx={{ marginTop: '5px', background: '#05b4cc', color: '#fff !important', width: '100%' }} variant="contained">Transfer</Button>
        </Grid>
        
    </Grid>

    <Divider component="li" />

    <List dense={dense}>
       {Object.keys(userDetails).length > 0 && userDetails?.balance.map((item, index) => (
            <ListItem onClick={() => {
              item?.code === "btt" ? setIsBTT(true) : setIsBTT(false);
              item?.code === "btt" || item?.code === "usdt" ? setwithdrawModal(true) : openReddemModal(item);
            }} key={index} secondaryAction={
                <Typography variant="body1" sx={{ fontSize: 20, marginTop: '5px', fontWeight: 'bold'}} color="text.secondary" gutterBottom>
                    ${(item?.balance * item?.value).toFixed(item?.limit)}
                </Typography>
            }>
                <ListItemAvatar>
                
                {(item?.code == 'btt' || item?.code == 'usdt') && <Avatar sx={{ width: 40, height: 40, marginRight: 1 }} style={{ background: 'transparent', borderRadius: '0px' }}>
                    <img style={{ width: 40 }} src={getIcon(item?.code)} alt="USDT" />
                </Avatar>}

                {(item?.code != 'btt' && item?.code != 'usdt') && <Avatar sx={{ width: 20, height: 20, marginLeft: '10px' }} style={{ background: 'transparent', borderRadius: '0px' }}>
                    <img style={{ width: 20 }} src={getIcon(item?.code)} alt="USDT" />
                </Avatar>}



                </ListItemAvatar>
                <ListItemText primary={item?.code.replace("_", " ").toUpperCase()} primaryTypographyProps={{ sx: { marginLeft: 0 } }} secondary={item?.balance.toFixed(item?.limit)} />
            </ListItem>
            ))}
    </List></>}

    <WithdrawDialog onUnlock={unlockStake} balance={userDetails?.balance[1]} isBtt={isBTT} userDetails={userDetails} open={withdrawModal} onClose={() => {setwithdrawModal(false);}} onSubmit={handleWithDrawSubmit}/>
    <TransferDialog open={transferModal} onClose={() => {setTransferwModal(false)}} onTransfer={handleTransferSubmit}/>
    <ConvertDialog open={bttConvertModal} userDetails={userDetails} onClose={() => {setbttConvertModal(false)}} onTransfer={handleConvertSubmit}/>
    <ReddemDialog open={reedemModal} name={coinName} coinCode={coin_code} onSubmit={handleReddem} onClose={() => {setreedemModal(false)}} />

    </>
    
    )
  }
  
  export default React.memo(Wallet)

