import throttle from 'lodash/throttle';
import { applyMiddleware, createStore } from 'redux';
import { loadState, saveState } from './localStorage';
import thunk from 'redux-thunk';
import rootReducer from './reducer';

const logger = ({ getState }) => next => action => {
  const returnValue = next(action);
  return returnValue;
};

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(logger, thunk)
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

export default store;