import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import InputLabel from '@mui/material/InputLabel';


import mainlogo from '../assets/rocket.png'

import usdtlogo from '../assets/usdt.png'
import slplogo from '../assets/slp.png'
import xeclogo from '../assets/xec.png'
import jasmylogo from '../assets/jasmy.png'
import { lightBlue } from '@mui/material/colors';

import transferTokens, { connectWallet } from '../utils/transferTokensFunction.js';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff', // your primary color
    }
  }
});

const styles = theme => ({
    multilineColor:{
        color:'red'
    }
});

const plans = [
  {
    "name": "META",
    "datetoExpire": "2025-02-10 15:41:59",
    "isActive": true,
    "amount": 25,
    "package_id": 10,
    "extendAmount": 20
  },
  {
      "name": "SILVER",
      "datetoExpire": "2025-02-10 15:41:59",
      "isActive": true,
      "amount": 50,
      "package_id": 1,
      "extendAmount": 20
  },
  {
      "name": "PEARL",
      "datetoExpire": null,
      "isActive": false,
      "amount": 100,
      "package_id": 2,
      "extendAmount": 20
  },
  {
      "name": "EMERALD",
      "datetoExpire": null,
      "isActive": false,
      "amount": 200,
      "package_id": 3,
      "extendAmount": 20
  },
  {
      "name": "GOLD",
      "datetoExpire": null,
      "isActive": false,
      "amount": 400,
      "package_id": 4,
      "extendAmount": 20
  },
  {
      "name": "PLATINUM",
      "datetoExpire": null,
      "isActive": false,
      "amount": 800,
      "package_id": 5,
      "extendAmount": 20
  },
  {
      "name": "DIAMOND",
      "datetoExpire": null,
      "isActive": false,
      "amount": 1600,
      "package_id": 6,
      "extendAmount": 20
  },
  {
      "name": "BLUE DIAMOND",
      "datetoExpire": null,
      "isActive": false,
      "amount": 3200,
      "package_id": 7,
      "extendAmount": 20
  },
  {
      "name": "ROYAL DIAMOND",
      "datetoExpire": null,
      "isActive": false,
      "amount": 6400,
      "package_id": 8,
      "extendAmount": 20
  },
  {
      "name": "CROWN",
      "datetoExpire": null,
      "isActive": false,
      "amount": 12800,
      "package_id": 9,
      "extendAmount": 20
  }
]

export default function SignUp() {

  const application = useSelector(state => ({
    ...state.application
  }));

  

  const dispatch = useDispatch();
  let history = useHistory();

  const value=queryString.parse(window.location.search);
  const token=value.u;

  const customRanges = [
    { min: 50, max: 500, label: "Range 1" },
    { min: 501, max: 1500, label: "Range 2" },
    { min: 1501, max: 2500, label: "Range 3" },
    { min: 2501, max: 100000, label: "Range 3" },
    // Add more custom ranges as needed
  ];

  const getLogo = {
    usdt: usdtlogo,
    xec: xeclogo,
    jasmy: jasmylogo,
    slp: slplogo
  }


  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowpass] = React.useState(false);
  const [showPassword2, setShowpass2] = React.useState(false);
  const [amount, setAmount] = React.useState(50);
  const [package_id, setPackageId] = React.useState(1);
  const [coin_code, setCoinCOde] = React.useState('usdt');


  const handleChange = (event) => {
    setPackageId(event.target.value);
  };

  const handleCoinChange = (event) => {
    setCoinCOde(event.target.value);
  };


  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  }

  const checkRange = (amount) => {
    for (const range of customRanges) {
      if (amount >= range.min && amount <= range.max) {
        return true;
      }
    }
    return false;
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get('Secret-id'))

    if (data.get('Secret-id') == "" || data.get('Secret-id').length < 4){
      alert("Secret Pin is not valid")
      return false;
    }

    try{
      let o = parseInt(data.get('Secret-id')) 
      if (o<1){
        alert("Secret Pin Should be numeric.")
        return false;
      }
    }
    catch(err){
      alert("Secret Pin Should be numeric.")
      return false;
    }

    let o = parseInt(data.get('Secret-id'))
    let o2 = parseInt(data.get('Secret-id2'))
    if (o != o2){
      alert("Secret Pin and Confirm Pin shoud be same")
      return false;
    }
    setOpen(true);
    _connectWallet(data.get('Referral-id'), data.get('package_id'), data.get('Secret-id'))
  };



  const _connectWallet = async (sponser_id, package_id, pin) => {
    const { network, accounts } = await connectWallet();
    if (network?.chainId != `${process.env.REACT_APP_NETWORK_ID}`){
      alert("Wrong network selected")
      setOpen(false)
      return;
    }
    if (accounts) {
      if (sponser_id != ""){
        check_sponserId(sponser_id, accounts[0], package_id, pin)
      }
      else{
        alert("Please enter sponser id")
        setOpen(false);
      }
    }
  };


  const check_sponserId = (sponser_id, address, package_id, pin) => {
    
    secureAxios
        .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/check_sponser`, {sponser: sponser_id.trim(), address: address}).then(response => {
          if (response.data?.success){
            pay(address, sponser_id.trim(), package_id, pin);
          }
        }).catch(error => {
          setOpen(false);
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }

        })
  }

  const register = (sponser_id, address, tx_hash, pin, package_id) => {
    
    secureAxios
        .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/register`, {sponser: sponser_id, address: address, tx_hash: tx_hash, pin: pin, coin_code: 'usdt', modeOfMint: 1, package_id: package_id}).then(response => {
          if (response.data?.success){
            localStorage.setItem('accessToken', response.data?.token?.access);
            dispatch({
              type: LOGIN,
              payload: {
                profile: {
                  account: address        
                },
                isAuthenticated: true
              }
            });
            history.push("/home");
          }
        }).catch(error => {
          setOpen(false);
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }

        })
  }

const pay = async (address, sponser_id, package_id, pin) => {
  try{
    const package_info = plans.find(plan => plan.package_id == package_id);

    const txn = await transferTokens(address, parseFloat(package_info?.amount))
    if (txn?.transactionHash) {
      register(sponser_id, address, txn?.transactionHash, pin, package_id)
    }
  }
  catch(e){
    setOpen(false);
    alert("Balance not available")
  }
}



  React.useEffect(() => {
    const bodyElt = document.querySelector("body");
    bodyElt.style.background = "rgb(26,26,26)";

    return //This is important
  
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <IBackdrop isOpen={open} msg={"Signin progress"}/>
      <Container component="main" fixed>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 5,
            paddingLeft:5,
            paddingRight:5,
            alignItems: 'center',
            boxShadow:  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
          
          }}
        >
          <img style={{width: 160}} src={mainlogo} alt="fireSpot"/>

          <Typography component="h1" style={{color: "#fff", marginTop: '30px'}} className='signup_span' variant="h5">
            Sign Up
          </Typography>
          <Box component="form" className='sign_up' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          
            <TextField
              margin="normal"
              required
              fullWidth
              name="Referral-id"
              label="Referral Id"
              type="text"
              variant="outlined"               
              id="referral-id"
              defaultValue={token}
              autoComplete="referral-id"
            />
            <InputLabel style={{marginTop: '2px', fontSize: '10px'}}>If you don't have referral I'd, please use METADEX</InputLabel>
            <TextField
              margin="normal"
              required
              fullWidth
              name="Secret-id"
              label="Secret Pin"
              type={showPassword ? "text" : "password"}
              variant="outlined"               
              id="secret-id"
              sx={{ input: { color: '#fff', borderRadius: '15%' } }} 
              autoComplete="secret-id"
              InputProps={{
                endAdornment: !showPassword ? <Visibility onClick={() => setShowpass(true)}/>  : <VisibilityOff onClick={() => setShowpass(false)}/>
              }}
            />
             <TextField
              margin="normal"
              required
              fullWidth
              name="Secret-id2"
              label="Confirm Pin"
              type={showPassword2 ? "text" : "password"}
              variant="outlined"               
              id="secret-id2"
              sx={{ input: { color: '#fff' } }} 
              autoComplete="secret-id2"
              InputProps={{
                endAdornment: !showPassword2 ? <Visibility onClick={() => setShowpass2(true)}/>  : <VisibilityOff onClick={() => setShowpass2(false)}/>
              }}
            />
            <Select
              value={package_id}
              name='package_id'
              style={{
                marginTop: '10px',
              }}
              fullWidth
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            > 

              {plans.map((item, index) => (
                      <MenuItem key={index} value={item?.package_id}>
                        <span className='green_color'>{item?.name} : ${item?.amount}</span>
                      </MenuItem>
                    ))
              }            
              
            </Select> 
            


            <Button
              style={{
                borderRadius: 35,
                backgroundColor: "transparent",
                fontSize: "14px",
                marginTop: '10px',
                color: '#fff !important',
                backgroundColor: "#05b4cc",
                marginBottom: '30px',
                marginTop: '25px',
                width: '100%'
                
            }} variant="contained"
            type="submit"
            >
              Sign Up
            </Button>
            <Grid container style={{marginBottom: '40px'}}>
              <Grid item xs>
                <Link to="/" style={{textDecoration: 'none', color: '#fff', fontSize: '12px'}} variant="body2">
                  {"Have an account? Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}