
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
          rpc: {
              56: 'https://bsc-dataseed1.binance.org'
          },
          chainId: 56
      }
  },
  // binancechainwallet: {
  //   package: true,
  // }
};

const web3ModalConfig = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});


export default web3ModalConfig;