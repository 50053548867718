import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';


import secureAxios from '../../secureAxios';
import IBackdrop from '../backdrop.js';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import GridTable from '@nadavshaar/react-grid-table';

import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
  
    const Demo = styled('div')(({ theme }) => ({
      backgroundColor: 'transparent',
    }));
  
  
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: 'rgb(33, 43, 54)'
      },
      subItemtitle: {
        color: '#eee',
        opacity: '0.5',
        fontSize: '8px'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: '#f0f5f9',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      },
      pan: {
        boxShadow:  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        background: 'rgb(255, 255, 255)'
      }
    }));

    const text = {
        color: "rgb(33, 43, 54)",
        opacity: '0.7',
        fontSize: '11px',
        maxWidth: '250px'
    };

    const subItemtitle = {
      color: 'rgb(33, 43, 54)',
      fontSize: '13px'
    };
  
    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    }

  const Notifications = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const theme = useTheme();
      const classes = useStyles();
      


      
      return (
      <>

        <Alert icon={<NotificationsActiveIcon fontSize="inherit" />} severity="success">
            <p style={{textAlign: 'left', margin: '0px'}}>BTT Unlock Fee Schedule</p>
            <ul style={{textAlign: 'left', paddingInlineStart: '15px'}}>
                <li style={{textAlign: 'left'}}><strong>0 Days - 90 Days:</strong> 40% unlock fee</li>
                <li style={{textAlign: 'left'}}><strong>91 Days - 180 Days:</strong> 20% unlock fee</li>
                <li style={{textAlign: 'left'}}><strong>181 Days - 270 Days:</strong> 10% unlock fee</li>
                <li style={{textAlign: 'left'}}><strong>271 Days - 365 Days:</strong> 5% unlock fee</li>
                <li style={{textAlign: 'left'}}><strong>366 Days and over:</strong> 0% unlock fee</li>
            </ul>
        </Alert>

        <Alert style={{marginTop: '10px'}} icon={<NotificationsActiveIcon fontSize="inherit" />} severity="success">
            <p style={{textAlign: 'left', margin: '0px'}}>Offer Details</p>
            <p style={{textAlign: 'left', color: '#fff !important'}}>Join the Metadex Royal Diamond package for 6400 USDT and get a CryptoNaut NFT for free, along with a domestic tour and receive monthly royalties.</p>
            <p style={{textAlign: 'left', color: '#fff !important'}}>Join the Metadex Crown package for 12800 USDT and get a Nova NFT for free, along with an international tour and receive monthly royalties.</p>
        </Alert>

      </>
      
      )
  }
  
  export default Notifications

