import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const UpgradeDialog = ({ open, msg, title, onClose, onSubmit }) => {
  const [amount, setAmount] = useState('');


  const handleClose = () => {
    setAmount('');
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({ amount });
    setAmount('');
  };

  return (
    <Dialog className='dilog_popup' open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {msg}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(event) => setAmount(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;
