import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout } from './layouts';
import { Dashboard, CapitalStateMent, RewardStateMent, WithdrawalStateMent, TeamRewardStateMent, Swap} from './views/Dashboard';
import { Users, TeamOverview, TeamInfo } from './views/Users';
import Home from './views/Home';
import SignIn from './views/signin';
import SignUp from './views/signup';
import Welcome from './views/welcome';
import CoinTabs from './views/update_price';
import Wallet from './views/wallet';
import Main from './views/Main';
import Notifications from './views/Users/notifications';

const routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Main}/>
      <Redirect path="login" to="/"/>
      <Route exact path="/signup" component={SignUp}/>
      <RouteWithLayout layout={MainLayout} exact path="/home" component={Home}/>
      <RouteWithLayout layout={MainLayout} exact path="/dashboard" component={Dashboard}/>
      <RouteWithLayout layout={MainLayout} exact path="/swap" component={Swap}/>
      <RouteWithLayout layout={MainLayout} exact path="/deposit-statement" component={CapitalStateMent}/>
      <RouteWithLayout layout={MainLayout} exact path="/rewards-statement" component={RewardStateMent}/>      
      <RouteWithLayout layout={MainLayout} exact path="/transactions" component={WithdrawalStateMent}/>
      <RouteWithLayout layout={MainLayout} exact path="/team-rewards-statement" component={TeamRewardStateMent}/>
      <RouteWithLayout layout={MainLayout} exact path="/users" component={Users}/>
      <RouteWithLayout layout={MainLayout} exact path="/teamoverview/:typeof" component={TeamInfo}/>
      <RouteWithLayout layout={MainLayout} exact path="/teaminfo/:level/:typeof" component={TeamInfo}/>
      <RouteWithLayout layout={MainLayout} exact path="/user/wallet" component={Wallet}/>
      <RouteWithLayout layout={MainLayout} exact path="/notifications" component={Notifications}/>
      <Route exact path="/coin_update" component={CoinTabs}/> 
    </Switch>
  );
};

export default routes;