import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';

import web3ModalConfig from "../providerOption";
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';

import { Paper } from '@mui/material';
import mainlogo from '../assets/Binance-Icon-Logo.wine.png'
import logo from '../assets/rocket.png'
import { textAlign } from '@mui/system';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const theme = createTheme();

const styles = theme => ({
    multilineColor:{
        color:'red'
    }
});

const style = {"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '1px solid green'}}}

export default function Welcome() {
  const dispatch = useDispatch();
  let history = useHistory();

  const application = useSelector(state => ({
    ...state.application
  }));

  const [provider, setProvider] = React.useState();
  const [library, setLibrary] = React.useState();
  const [account, setAccount] = React.useState();
  const [network, setNetwork] = React.useState();
  const [chainId, setChainId] = React.useState();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const bodyElt = document.querySelector("body");
    bodyElt.style.background = "rgb(26,26,26)";
  
    return //This is important
   
  }, []);

  
  const handleAccountsChanged = (accounts) => {
    console.log({accounts})
  };

  const connectWallet = async () => {
    const provider = await web3ModalConfig.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();

    if (network?.chainId !== 56){
      alert("Wrong network selected")
      return;
    }
    setProvider(provider);
    setLibrary(library);
    if (accounts) setAccount(accounts[0]);
    setNetwork(network);
    setChainId(network.chainId);

    loginProcess(accounts[0], network.chainId);

  };

  const loginProcess = (address, chainId) => {
    setOpen(true);
    secureAxios
        .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/login`, {address: address}).then(response => {
          setOpen(false);
          if (response.data?.success){
            localStorage.setItem('accessToken', response.data?.token?.access);
            dispatch({
              type: LOGIN,
              payload: {
                profile: {
                  account: address,
                  chainId: chainId,          
                },
                isAuthenticated: true
              }
            });
            history.push("/home");
          }
        }).catch(error => {
          setOpen(false);
          console.log(error)
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }

        })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    dispatch({
      type: LOGIN,
      payload: {
        profile: {},
        isAuthenticated: true,
        accessToken: {},
        groups: {}
      }
    });
    history.push("/home")

  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
  };


  return (
    <ThemeProvider theme={theme}>
    <IBackdrop isOpen={open} msg={"Signin progress"}/>
      <Container component="main" maxWidth="xs">
        <Typography component="h4" style={{color: 'rgb(255, 255, 255)', textAlign: 'center', lineHeight: '1.8', marginTop: '50px', fontWeight: 'bold'}} variant="h5">
            <Avatar sx={{ m: 1, bgcolor: 'transparent' }} style={{margin: '0px auto', borderRadius: '0px'}}>
                <img style={{width: 34}} src={logo} alt="fireSpot"/>
            </Avatar>
        </Typography>
        <Typography component="h1" style={{color: 'rgb(255, 255, 255)', lineHeight: '1.8', marginTop: '10px', fontWeight: 'bold'}} variant="h5">
                DeFi MINTING IS THE DECENTRALIZED PLATFORM ON THE BINANCE SMART CHAIN NETWORK
        </Typography>

        <Typography component="h3" style={{color: 'rgb(192, 192, 192)', marginTop: '25px', lineHeight: '1.5', fontSize: '20px'}} variant="h5">
                DeFi MINTING will empower crypto currency projects with ability to distribute  DeFi and raise liquidity
        </Typography>

        <Button
              style={{
                borderRadius: 15,
                color: 'rgb(255, 255, 255)',
                backgroundColor: "rgb(252, 213, 53)",
                fontSize: "14px",
                padding: '10px 0px',
                marginTop: '30px',
                marginBottom: '10px',
                fontWeight: 'bold',
                width: '100%'
            }} variant="contained"
            onClick={connectWallet}
            >
              Sign In
            </Button>

        <Link to="/signup" style={{textDecoration: 'none'}}>

            <Button
              style={{
                borderRadius: 15,
                color: 'rgb(255, 255, 255)',
                backgroundColor: "rgb(86, 188, 55)",
                fontSize: "14px",
                padding: '10px 0px',
                marginTop: '10px',
                fontWeight: 'bold',
                marginBottom: '10px',
                width: '100%'
            }} variant="contained"
            >
              Sign UP
            </Button>

            </Link>

            
            <a href="https://pancakeswap.finance/swap" style={{textDecoration: 'none'}}>
            <Button
              style={{
                borderRadius: 15,
                color: 'rgb(255, 255, 255)',
                backgroundColor: "rgb(18, 32, 45)",
                fontSize: "14px",
                padding: '10px 0px',
                marginTop: '10px',
                fontWeight: 'bold',
                marginBottom: '10px',
                width: '100%',
                textAlign: 'center'
            }} variant="contained"
            >
                <Grid container   justifyContent="center" direction="row" alignItems="center">
                <Grid item>
                    <Avatar style={{background: 'transparent', marginRight: '7px'}}>
                    <img style={{width: 25}} src={mainlogo} alt="fireSpot"/>
                </Avatar>
                </Grid>
                <Grid item>
                BUY ON PANCAKESWAP
                </Grid>
                </Grid>
              
            </Button>
            </a>
      </Container>
    </ThemeProvider>
  );
}