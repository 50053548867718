import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ethers } from 'ethers';

// import "../assets/libs/bootstrap/css/bootstrap-grid.min.css";
// import "../assets/libs/animate/animate.css";
// import "../assets/libs/main.css";
// import "../assets/libs/media.css";


import uz1 from "../assets/libs/img/uz1.svg";
import mainLogo from "../assets/rocket.png"
import uz2 from "../assets/libs/img/uz2.svg";
import uz7 from "../assets/libs/img/uz7.svg";
import uz8 from "../assets/libs/img/uz8.svg";
import sc2 from  "../assets/libs/img/sc2.svg";
import sc3 from  "../assets/libs/img/sc3.svg";
import sc4 from  "../assets/libs/img/sc4.svg";



import web3ModalConfig from "../providerOption";
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';

import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';


function Main() {
    const [scrolled, setScrolled] = useState(false);
    const [isCssLoaded, setIsCssLoaded] = useState(false);


    const dispatch = useDispatch();
    let history = useHistory();

    const application = useSelector(state => ({
        ...state.application
    }));

    const [provider, setProvider] = React.useState();
    const [library, setLibrary] = React.useState();
    const [account, setAccount] = React.useState();
    const [network, setNetwork] = React.useState();
    const [chainId, setChainId] = React.useState();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (application.isAuthenticated === true){
            window.location.href = '/home';
        }
    }, []);

    useEffect(() => {
        const loadCss = async () => {
            // Dynamically import CSS files
            await Promise.all([
              import('../assets/libs/bootstrap/css/bootstrap-grid.min.css'),
              import('../assets/libs/animate/animate.css'),
              import('../assets/libs/main.css')
            ]);
      
            // Set the state to indicate that CSS files are loaded
            setIsCssLoaded(true);
          };
      
          loadCss();
        
      }, []);

    

    const connectWallet = async () => {
        const provider = await web3ModalConfig.connect();
        const library = new ethers.providers.Web3Provider(provider);
        const accounts = await library.listAccounts();
        const network = await library.getNetwork();
    
        if (network?.chainId !== 56){
          alert("Wrong network selected")
          return;
        }
        setProvider(provider);
        setLibrary(library);
        if (accounts) setAccount(accounts[0]);
        setNetwork(network);
        setChainId(network.chainId);
    
        loginProcess(accounts[0], network.chainId);
    
      };
    
      const loginProcess = (address, chainId) => {
        setOpen(true);
        secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/login`, {address: address}).then(response => {
              setOpen(false);
              if (response.data?.success){
                localStorage.setItem('accessToken', response.data?.token?.access);
                dispatch({
                  type: LOGIN,
                  payload: {
                    profile: {
                      account: address,
                      chainId: chainId,          
                    },
                    isAuthenticated: true,
                    isDrawerOpen: false
                  }
                });
                window.location.href = '/home';
              }
            }).catch(error => {
              setOpen(false);
              console.log(error)
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
    
            })
      }
    
      const refreshState = () => {
        setAccount();
        setChainId();
        setNetwork("");
      };
    

    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 100;
          if (isScrolled !== scrolled) {
            setScrolled(isScrolled);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [scrolled]);
    

  return (
    <>
    {!isCssLoaded && <h1>Loading the App...</h1>}
    {isCssLoaded && <div id="wrapper">
        <IBackdrop isOpen={open} msg={"Signin progress"}/>
      <div className={scrolled ? 'header fixed' : 'header'}>
        <div className="container">
            <div className="wr">
                <div className="logo">
                <img src={mainLogo} width="100" alt="" />
                </div>
                <div className='button_box' style={{position: 'relative', right: '0px', display: 'flex', flexFlow: 'row'}}>
                    <a onClick={connectWallet} style={{marginRight: '10px'}} href="#" className="btn-call">Login</a>
                    <Link  className="btn-call" to="/signup">Register</Link>
                </div>
            </div>
        </div>
      </div>

      <div className="circle-bg-bx">
        <div className="top-box">
        <div className="wrap">
            <div className="container">
            <div className="wr">
                <div className="tt">
                <ul className="soc-list">
                    <li><a target="_blank" href="https://t.me/BTTBitTorrent"><img src={sc2} alt="" /></a></li>
                    <li><a target="_blank" href="https://twitter.com/BitTorrent"><img src={sc3} alt="" /></a></li>
                </ul>
                <div className="uzor uz1"><img src={uz1} alt="" /></div>
                <div className="uzor uz2"><img src={uz2} alt="" /></div>
                <div className="log animation_1"><img src={mainLogo} alt="" /></div>
                <div className="slogan animation_1">
                    Bringing You the <span>Future</span> of BitTorrent — Today!
                </div>
                <div className="text">
                BitTorrent is one of the world's largest decentralized file
                sharing protocol in the world by number of users. The
                BitTorrent peer-to-peer protocol was invented by Bram
                Cohen, released in July 2001, and has powered torrent
                clients used around the world since then.
                </div>
                </div>

                <div className="thebuttons">
                <a href="https://pancakeswap.finance/swap?outputCurrency=0x352Cb5E19b12FC216548a2677bD0fce83BaE434B" className="btn" target="_blank">Buy on PancakeSwap</a>
                <a href="https://app.uniswap.org/swap?inputCurrency=0xc669928185dbce49d2230cc9b0979be6dc797957" className="btn" target="_blank">Buy on UniSwap</a>
                <a href="https://www.binance.com/en/trade/BTTC_USDT" className="btn" target="_blank">Buy on Binance</a>
                <a href="https://www.gate.io/trade/BTT_USDT" className="btn" target="_blank">Buy on Gate.io</a>
                </div>

                <div className="text-bx">
                <div className="title-bx animation_1">What is MetaDex | Web3</div>
                <div className="texts animation_1">
                    <div className="bx bx1">
                    <p>MetaDex Blockchaln Verified, 100% Decentralized and Worldwide program allow
                        everyone to join and make unlimited BTTC & USDT. All commissions are
                        distributed real time and instantly without involving any third party. MetaDex have
                        four Types of Program simultaneausly running to generate maximum profit. A smart
                        contract is a self-executing contract with the terms of the agreement between buyer
                        and seller being directly written into lines of code. The code and the agreements
                        contained therein exist across a distributed, decentralized blockchain network. The
                        smart contrac</p>
                    </div>
                    <div className="bx bx2">
                    <p>There are many different paths that the future development of Web
                        3.0 could take. Here are a few of the Web3 technologies we’re
                        starting to see deployed today:
                        <ul>
                            <li>Cryptocurrency</li>
                            <li>Smart contract</li>
                            <li>DeFi (Decentralized Finance)</li>
                            <li>NFTs (Non-Fungible Tokens)</li>
                            <li>DAOs (Decentralized Autonomous Organization)</li>
                            <li>Metaverse</li>
                            <li>DApps</li>
                        </ul></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      </div>

      <div className="info-box">
        <div className="container">
        <div className="wr">
            <div className="title-bx animation_1">Token price and vesting information</div>
            
            <div className="tb-wr animation_1">
            <div className="bx bx1">
                <div className="s1">Market cap</div>
                <div className="s2">$1.37B</div>
            </div>
            <div className="bx bx2">
                <div className="s1">Reported circulating</div>
                <div className="s2">990B BTT</div>
            </div>
            </div>
            <div className="tb-wr animation_1">
            <div className="bx bx3">
                <div className="s1">Total supply</div>
                <div className="s2">990B BTT</div>
            </div>
            <div className="bx bx4">
                <div className="s1">Market Dominance</div>
                <div className="s2">0.0589%</div>
            </div>
            </div>
            
        </div>
        </div>
      </div>

      <div className="feature-box">
        <div className="container">
        <div className="wr">
            <div className="title-bx animation_1">What Makes BitTorrent Unique?</div>
            <div className="feat-text-bx">
            <div className="tt1 animation_1">
                <div className="ico"><img src="img/cc.svg" alt="" /></div>
                <div className="t">
                BitTorrent is a popular peer-to-peer (P2P) file sharing and
                torrent platform which has become increasingly decentralized in
                recent years.
                Originally released in July 2001, BitTorrent was purchased by
                blockchain platform TRON in July 2018.
                Since its acquisition, BitTorrent has added various new tools,
                with a dedicated native cryptocurrency token, BTT, released in
                February 2019. BTT was launched on TRON’s own blockchain,
                using its TRC-10 standard.
                According to its official literature, BitTorrent is currently the
                “largest d</div>
            </div>
            </div>
            <div className="socials-box animation_1">
            <div className="h3">BitTorrent Socials:</div>
            <div className="wrap">
                <div className="bx bx1">
                <ul className="list">
                    <li>
                    <div className="t1">Website</div>
                    <div className="t2"><a  target="_blank" href="https://bt.io/">bt.io</a></div>
                    </li>
                    <li>
                    <div className="t1">Telegram ANN</div>
                    <div className="t2"><a  target="_blank" href="https://t.me/BTTBitTorrent">t.me/BTTBitTorrent</a></div>
                    </li>
                </ul>
                </div>
                <div className="bx bx2">
                <ul className="list">
                    <li>
                    <div className="t1">Twitter</div>
                    <div className="t2"><a  target="_blank" href="https://twitter.com/BitTorrent">twitter.com/BitTorrent</a></div>
                    </li>
                    <li>
                    <div className="t1">Whitepaper</div>
                    <div className="t2"><a  target="_blank" href="https://www.bittorrent.com/btt/btt-docs/BitTorrent_(BTT)_White_Paper_v0.8.7_Feb_2019.pdf">White Paper</a></div>
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
        <div className="copy-bx">
            <div className="t" style={{textAlign: 'center', margin: '0px auto'}}>Copyright © 2024 Metadex Limited. All rights reserved</div>
        </div>
        </div>
      </div>
    </div>}</>
  );
}

export default Main;