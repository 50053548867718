import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from './store';

const browserHistory = createBrowserHistory();


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={browserHistory}>    
      <App />
    </BrowserRouter>
  </Provider>
, document.getElementById('root'))

