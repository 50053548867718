import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';


import secureAxios from '../../secureAxios';
import IBackdrop from '../backdrop.js';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import GridTable from '@nadavshaar/react-grid-table';

import { useParams } from 'react-router-dom';


const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  
  
  

  const titles = ['Universal referral', 'Direct referral'];
  const subTitle = ['members added in your team', 'members joined through direct referral']


  function generate(element) {
      return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
          key: value,
        }),
      );
    }
  
    const Demo = styled('div')(({ theme }) => ({
      backgroundColor: 'transparent',
    }));
  
  
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: 'rgb(33, 43, 54)'
      },
      subItemtitle: {
        color: '#eee',
        opacity: '0.5',
        fontSize: '8px'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: '#f0f5f9',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      },
      pan: {
        boxShadow:  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        background: 'rgb(255, 255, 255)'
      }
    }));

    const text = {
        color: "rgb(33, 43, 54)",
        opacity: '0.7',
        fontSize: '11px',
        maxWidth: '250px'
    };

    const subItemtitle = {
      color: 'rgb(33, 43, 54)',
      fontSize: '13px'
    };
  
    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    }

    function TabPanel(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    const columns = [
      {
        id: 1, 
        field: 'username', 
        label: 'Username'
      }, 
      {
        id: 2, 
        field: 'stake', 
        label: 'Package'
      },
      {
        id: 3, 
        field: 'address', 
        label: 'address'
      }
    ]
  
    const data = {
      total: {
        member: 0,
        stake: 0,
        userDetails: []
      },
      direct: {
        member: 0,
        stake: 0,
        userDetails: []
      }
      
    }

  const Users = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const theme = useTheme();
      const classes = useStyles();
      const [dense, setDense] = React.useState(false);
      const [secondary, setSecondary] = React.useState(false);
      const [open, setOpen] = React.useState(false);
      const [statements, setStatements] = React.useState(data);
      const [value, setValue] = React.useState(0);
      const [showTab, setShowTab] = React.useState(true);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const handleChangeIndex = (index) => {
        setValue(index);
      };

      useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

      const memberDetails = () => {
        setOpen(true);

        const urlParams = new URLSearchParams(window.location.search);
        
        // Get specific query parameters
        let showLevel = urlParams.get('showlevel');
        const packageId = urlParams.get('package_id');
        
        if (showLevel == undefined || showLevel == null){
          showLevel = false;
        }
        else{
          showLevel = true;
        }
        setShowTab(!showLevel);
        if (showLevel==true){
          setValue(1)
        }
        secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/team/list`, {'show_level': showLevel, 'package_id': parseInt(packageId)}).then(response => {
              setOpen(false);
              
              setStatements(response.data);
              
            }).catch(error => {
              setOpen(false);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
    
            })
      }

      React.useEffect(() => {
        memberDetails()
        return
      }, []);


      
      return (
      <>
      <IBackdrop isOpen={open} msg={"Signin progress"}/>

      {showTab && <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#05b4cc', // Custom indicator color
            },
          }}
          textColor="inherit"
          variant="fullWidth"
        >
          {Object.keys(statements).map((item, index) => {
            return (<Tab label={titles[index]} />)
          })}
        </Tabs>}

        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <List style={{marginTop: '0px'}} dense={dense}>
              <ListItem
                  secondaryAction={
                    <Grid container direction="row" alignItems="center">
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12}} variant="subtitle2">{statements?.["total"].member}</Typography>
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 5, marginLeft: '10px'}} variant="subtitle2"><PeopleRoundedIcon/></Typography>
                    </Grid>
                    
                  }
              >
                  <ListItemText
                  className={classes.title}
                  primaryTypographyProps={{style: {fontSize: '12px'}}}
                  primary={`Universal Referral`}
                  />
              </ListItem>

              <ListItem
                  secondaryAction={
                    <Grid container direction="row" alignItems="center">
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12}} variant="subtitle2">{statements?.["total"].stake.toFixed(2)}</Typography>
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 5, marginLeft: '10px'}} variant="subtitle2"><AccountBalanceWalletOutlinedIcon/></Typography>
                    </Grid>
                    
                  }
              >
                  <ListItemText
                  className={classes.title}
                  primaryTypographyProps={{style: {fontSize: '12px'}}}
                  primary={`Universal Referral Amount`}
                  />
              </ListItem>
              
          </List>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <GridTable style={{marginBottom: '70px'}} columns={columns} rows={statements?.["direct"].userDetails}/>
          </TabPanel>
          
        </SwipeableViews>
      </>
      
      )
  }
  
  export default Users

